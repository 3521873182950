import React from "react"

import { Link } from "gatsby"

// Component Imports
import Text from "../components/Text"
import Image from "gatsby-image"

interface Props {
  category: string
  title: string
  desc: string
  img: any
  url: string
}

const FeaturedPost = ({ category, title, desc, img, url }: Props) => {
  return (
    <div className="md:flex-row md:items-center group relative flex flex-col w-full cursor-pointer">
      <Link to={`/news/${url}`}>
        <span className="sr-only">{title}</span>
        {/* Outline for xl desktops */}

        <div className="relative cursor-pointer">
          <div className="md:w-56 lg:w-32 lg:h-32 block w-full h-56 overflow-hidden">
            <img
              src={img}
              alt={title}
              className="group-hover:scale-110 ease-easeInOutQuint block object-cover w-full h-full transition-all duration-500 transform"
            />
          </div>
          {/* Outline for mobile */}
          <div className="ease-easeInOutQuint newsHeaderRectangle group-hover:translate-x-0 group-hover:translate-y-0 group-hover:scale-95 absolute inset-0 transition-all duration-500 transform translate-x-2 -translate-y-2 border-2 border-gray-500"></div>
        </div>
      </Link>

      <Link to={`/news/${url}`} className="md:mt-0 md:ml-8 block mt-3">
        <Text variant="p" className="text-xs font-semibold uppercase">
          {category}
        </Text>
        <div className="xl:pr-4 mt-2">
          <h3 className="xl:font-medium xl:text-lg group-hover:text- font-bold tracking-wide">
            {title}
          </h3>
          <Text variant="p" className="lg:font-medium text-sm">
            {desc}
          </Text>
        </div>
      </Link>
    </div>
  )
}

export default FeaturedPost
