import React, { ReactElement, useState } from "react";

import { useStaticQuery, graphql, Link } from "gatsby";

// Component imports
import FeaturedPost from "../components/FeaturedPost";
import LatestPost from "../components/LatestPost";
import CategoryFilter from "../components/CategoryFilter";
import Layout from "../components/Layout";
import Background from "gatsby-background-image";
import Section from "../components/Section";
import Text from "../components/Text";
import { Fade } from "react-awesome-reveal";
import SEO from "../components/SEO";

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import useWindowSize from "../components/hooks/useWindowSize";

function News(): ReactElement {
    const { width } = useWindowSize();

    const [category, setCategory] = useState("all");
    const [viewAll, setViewAll] = useState(false);

    // Handle category changes
    const handleCategoryChange = (filter: string) => {
        setCategory(filter);
    };

    // Render 'LatestPosts'
    const renderPost = (data: any) => {
        if (!data) return <Text variant="p">No posts found...</Text>;
        console.log(data);
        return (
            <InView triggerOnce threshold={0.5}>
                {({ inView, ref }) => (
                    <div ref={ref}>
                        <motion.div
                            initial={{ y: 50, opacity: 0 }}
                            animate={inView && { y: 0, opacity: 1 }}
                        >
                            <LatestPost
                                desc={data.node.excerpt}
                                title={data.node.title}
                                url={data.node.slug}
                                img={data.node.feature_image}
                                category={
                                    data.node.tags[1].name === "gameUpdates"
                                        ? "game updates"
                                        : data.node.tags[1].name
                                }
                            />
                        </motion.div>
                    </div>
                )}
            </InView>
        );
    };

    const data = useStaticQuery(graphql`
        query {
            background: file(
                relativePath: { eq: "background_header_blurred.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            page_bg: file(relativePath: { eq: "page_bg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            bigFeature: allGhostPost(
                filter: { tags: { elemMatch: { name: { eq: "bigFeature" } } } }
            ) {
                edges {
                    node {
                        title
                        slug
                        excerpt
                        feature_image
                    }
                }
            }

            featuredArticles: allGhostPost(
                filter: { tags: { elemMatch: { name: { eq: "featured" } } } }
                limit: 3
                sort: { fields: published_at, order: DESC }
            ) {
                edges {
                    node {
                        title
                        excerpt
                        slug
                        feature_image
                        tags {
                            name
                        }
                    }
                }
            }

            allNews: allGhostPost(
                filter: { primary_tag: { name: { eq: "news" } } }
                sort: { fields: created_at, order: DESC }
            ) {
                edges {
                    node {
                        title
                        excerpt
                        slug
                        feature_image
                        tags {
                            name
                        }
                    }
                }
            }

            recentNews: allGhostPost(
                filter: { primary_tag: { name: { eq: "news" } } }
                sort: { fields: created_at, order: DESC }
                limit: 4
            ) {
                edges {
                    node {
                        title
                        excerpt
                        slug
                        feature_image
                        tags {
                            name
                        }
                    }
                }
            }
        }
    `);

    const pageBg = [
        `linear-gradient(hsla(0, 0%, 6%, .95), hsla(0, 0%, 6%, .95))`,

        data.page_bg.childImageSharp.fluid,
    ];

    return (
        <Layout>
            <SEO
                title="News"
                description="Read up on the latest newsletters, announcements, updates and events on SpaceDelta!"
            />
            {/* Header */}
            <section className="md:translate-y-0 md:h-newsHeaderSmall xl:h-newsHeader relative z-10 h-screen transform -translate-y-16">
                <Background
                    fluid={data.background.childImageSharp.fluid}
                    className=" h-full bg-cover"
                >
                    <Section
                        verticalPadding={false}
                        className="md:items-start lg:py-4 flex flex-col items-center justify-center h-full py-8"
                    >
                        <div className="md:grid md:grid-cols-2 md:gap-8 lg:gap-2 md:items-center lg:items-center lg:grid-cols-2 xxl:grid-cols-3 block w-full">
                            {/* Title and Subtitle */}
                            <div>
                                <Fade
                                    triggerOnce
                                    duration={500}
                                    direction="top"
                                >
                                    <Link
                                        to={`/news/${data.bigFeature.edges[0].node.slug}`}
                                        className="ease-easeInOutQuint md:mb-0 group-hover:translate-x-4 hover:text-purple-400 xxl:pb-4 block mb-8 transition-all duration-500 transform"
                                    >
                                        <h2 className="lg:text-2xl mb-2 text-lg font-bold capitalize">
                                            {
                                                data.bigFeature.edges[0].node
                                                    .title
                                            }
                                        </h2>
                                        <p className="lg:text-base font-medium">
                                            {
                                                data.bigFeature.edges[0].node
                                                    .excerpt
                                            }
                                        </p>
                                    </Link>
                                </Fade>
                            </div>
                            {/* Thumbnail */}
                            <div className="group xxl:col-span-2 relative w-full cursor-pointer">
                                <Fade
                                    duration={500}
                                    triggerOnce
                                    direction="top"
                                    className="relative"
                                >
                                    <Link
                                        to={`/news/${data.bigFeature.edges[0].node.slug}`}
                                        className="block w-full"
                                    >
                                        <span className="sr-only">
                                            {
                                                data.bigFeature.edges[0].node
                                                    .title
                                            }
                                        </span>
                                        <div className="overflow-hidden">
                                            <img
                                                src={
                                                    data.bigFeature.edges[0]
                                                        .node.feature_image
                                                }
                                                alt={
                                                    data.bigFeature.edges[0]
                                                        .node.title
                                                }
                                                className="ease-easeInOutQuint group-hover:scale-110 block object-cover w-full h-full transition-all duration-500 transform"
                                            ></img>
                                        </div>
                                        <div className="xl:translate-x-8 xl:-translate-y-8 ease-easeInOutQuint newsHeaderRectangle group-hover:translate-x-0 group-hover:translate-y-0 group-hover:scale-95 absolute inset-0 transition-all duration-500 transform translate-x-2 -translate-y-2 border-2 border-gray-500"></div>
                                    </Link>
                                </Fade>
                            </div>
                        </div>
                    </Section>
                </Background>
            </section>

            {/* Main  */}
            <Background fluid={pageBg} className="bg-top bg-repeat">
                {/* Featured Articles */}
                {data.featuredArticles && (
                    <div className="bg-gray-700">
                        <Section>
                            <Text
                                variant="p"
                                className="lg:text-sm xxl:transform xxl:w-40 xxl:-rotate-90 xxl:-translate-x-32 xxl:translate-y-16 xxl:mb-0 md:mb-8 mb-8 text-xs font-semibold uppercase"
                            >
                                - Featured News -
                            </Text>
                            <div className="lg:flex-row lg:items-center lg:space-y-0 lg:space-x-4 flex flex-col space-y-12">
                                {data.featuredArticles.edges.map(
                                    (featuredArticle: any, i: number) => (
                                        <InView triggerOnce threshold={0.2}>
                                            {({ inView, ref }) => (
                                                <div
                                                    ref={ref}
                                                    className="lg:w-1/3"
                                                >
                                                    <motion.div
                                                        initial={{
                                                            y: 50,
                                                            opacity: 0,
                                                        }}
                                                        animate={
                                                            inView && {
                                                                y: 0,
                                                                opacity: 1,
                                                            }
                                                        }
                                                        transition={
                                                            width >= 1024 && {
                                                                delay: i * 0.25,
                                                            }
                                                        }
                                                    >
                                                        <FeaturedPost
                                                            category={
                                                                featuredArticle
                                                                    .node
                                                                    .tags[1]
                                                                    .name ===
                                                                "gameUpdates"
                                                                    ? "game updates"
                                                                    : featuredArticle
                                                                          .node
                                                                          .tags[1]
                                                                          .name
                                                            }
                                                            desc={
                                                                featuredArticle
                                                                    .node
                                                                    .excerpt
                                                            }
                                                            url={
                                                                featuredArticle
                                                                    .node.slug
                                                            }
                                                            title={
                                                                featuredArticle
                                                                    .node.title
                                                            }
                                                            img={
                                                                featuredArticle
                                                                    .node
                                                                    .feature_image
                                                            }
                                                        />
                                                    </motion.div>
                                                </div>
                                            )}
                                        </InView>
                                    )
                                )}
                            </div>
                        </Section>
                    </div>
                )}

                {/* Regular Articles */}

                <div className={!data.featuredArticles && "lg:mt-40"}>
                    <Section>
                        {/* Mobile category sort */}

                        <div className="lg:hidden">
                            <Text
                                variant="p"
                                className="mb-4 text-xs font-semibold uppercase"
                            >
                                - Category -
                            </Text>

                            <div className="relative">
                                <select
                                    className="bg-backgroundAlpha focus:outline-none block w-full p-4 font-semibold uppercase appearance-none"
                                    onChange={(e) => {
                                        setCategory(e.target.value);
                                    }}
                                >
                                    <option value="all">All</option>
                                    <option value="articles">Articles</option>
                                    <option value="gameUpdates">
                                        Game Updates
                                    </option>
                                    <option value="guides">Guides</option>
                                    <option value="misc">Misc.</option>
                                </select>
                                <div className="opacity-30 absolute inset-y-0 right-0 flex items-center px-2 text-white pointer-events-none">
                                    <svg
                                        className="w-4 h-4 fill-current"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div className="md:py-16 lg:py-0 lg:grid lg:grid-cols-4 container flex flex-col py-12">
                            {/* Latest Posts */}
                            <div className="lg:hidden">
                                <Text
                                    variant="p"
                                    className="xxl:transform xxl:w-40 xxl:-rotate-90 xxl:-translate-x-32 xxl:translate-y-16 xxl:mb-0 md:mb-8 mb-8 text-xs font-semibold uppercase"
                                >
                                    - Latest News -
                                </Text>
                            </div>
                            <div className="lg:pr-4 lg:border-r-2 lg:border-gray-500 lg:col-span-3 grid gap-20">
                                <div className="lg:block hidden -mb-40">
                                    <Text
                                        variant="p"
                                        className="lg:text-sm xxl:transform xxl:w-40 xxl:-rotate-90 xxl:-translate-x-32 xxl:translate-y-16 xxl:mb-0 md:mb-8 mb-8 text-xs font-semibold uppercase"
                                    >
                                        - Latest News -
                                    </Text>
                                </div>
                                {category === "all" && !viewAll ? (
                                    data.recentNews.edges.map(
                                        (latestPost: any) =>
                                            renderPost(latestPost)
                                    )
                                ) : category === "all" && viewAll ? (
                                    data.allNews.edges.map((latestPost: any) =>
                                        renderPost(latestPost)
                                    )
                                ) : category !== "all" &&
                                  data.allNews.edges.find(
                                      (latestPost: any) =>
                                          latestPost.node.tags[1].name ===
                                          category
                                  ) ? (
                                    data.allNews.edges.map(
                                        (latestPost: any) =>
                                            latestPost.node.tags[1].name ===
                                                category &&
                                            renderPost(latestPost)
                                    )
                                ) : (
                                    <Text
                                        variant="p"
                                        className="lg:-translate-y-24 xxl:-translate-y-32 transform"
                                    >
                                        No posts found...
                                    </Text>
                                )}

                                {/* View all button */}
                                {category === "all" && (
                                    <div className="md:pr-4 md:pb-4 flex justify-end">
                                        <button
                                            className="focus:outline-none focus:text-purple-600 hover:text-purple-600 ease-easeInOutQuint group flex items-center justify-end text-xs font-semibold uppercase transition-all duration-300"
                                            onClick={() => setViewAll(!viewAll)}
                                        >
                                            <span>
                                                {viewAll
                                                    ? "View less"
                                                    : "View all"}
                                            </span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="8.707"
                                                viewBox="0 0 16 8.707"
                                                className={`ml-2 transition-all duration-300 transform group-hover:translate-x-2 ease-easeInOutQuint transform ${
                                                    viewAll && "rotate-180"
                                                }`}
                                            >
                                                <g
                                                    id="Group_35"
                                                    data-name="Group 35"
                                                    transform="translate(-1716.5 -2177.146)"
                                                >
                                                    <line
                                                        id="Line_3"
                                                        data-name="Line 3"
                                                        x2="11"
                                                        transform="translate(1721.5 2181.5)"
                                                        fill="none"
                                                        stroke="#d86aff"
                                                        stroke-width="1"
                                                    />
                                                    <line
                                                        id="Line_6"
                                                        data-name="Line 6"
                                                        x2="1"
                                                        transform="translate(1716.5 2181.5)"
                                                        fill="none"
                                                        stroke="#d86aff"
                                                        stroke-width="1"
                                                    />
                                                    <line
                                                        id="Line_4"
                                                        data-name="Line 4"
                                                        x2="4"
                                                        y2="4"
                                                        transform="translate(1727.5 2177.5)"
                                                        fill="none"
                                                        stroke="#d86aff"
                                                        stroke-width="1"
                                                    />
                                                    <line
                                                        id="Line_5"
                                                        data-name="Line 5"
                                                        y1="4"
                                                        x2="4"
                                                        transform="translate(1727.5 2181.5)"
                                                        fill="none"
                                                        stroke="#d86aff"
                                                        stroke-width="1"
                                                    />
                                                </g>
                                            </svg>
                                        </button>
                                    </div>
                                )}
                            </div>

                            {/* Category selector */}
                            <div className="lg:block lg:pl-4 xl:pl-16 hidden">
                                <div
                                    className="lg:flex lg:flex-col lg:sticky"
                                    style={{ top: 100 }}
                                >
                                    <motion.div
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                    >
                                        <CategoryFilter
                                            filter="all"
                                            text="All"
                                            className="mb-3"
                                            category={category}
                                            handleCategoryChange={
                                                handleCategoryChange
                                            }
                                        />
                                    </motion.div>
                                    <motion.div
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                    >
                                        <CategoryFilter
                                            text="Articles"
                                            filter="articles"
                                            className="mb-3"
                                            category={category}
                                            handleCategoryChange={
                                                handleCategoryChange
                                            }
                                        />
                                    </motion.div>
                                    <motion.div
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                    >
                                        <CategoryFilter
                                            filter="gameUpdates"
                                            text="Game Updates"
                                            className="mb-3"
                                            category={category}
                                            handleCategoryChange={
                                                handleCategoryChange
                                            }
                                        />
                                    </motion.div>
                                    <motion.div
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                    >
                                        <CategoryFilter
                                            filter="guides"
                                            text="Guides"
                                            className="mb-3"
                                            category={category}
                                            handleCategoryChange={
                                                handleCategoryChange
                                            }
                                        />
                                    </motion.div>
                                    <motion.div
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                    >
                                        <CategoryFilter
                                            filter="misc"
                                            text="Misc."
                                            category={category}
                                            handleCategoryChange={
                                                handleCategoryChange
                                            }
                                        />
                                    </motion.div>
                                </div>
                            </div>
                        </div>
                    </Section>
                </div>
            </Background>
        </Layout>
    );
}

export default News;
