import React, { useRef, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";

interface Props {
    filter?: string;
    text?: string;
    className?: string;
    category?: string;
    handleCategoryChange?: Function;
    enableHoverEffect?: boolean;
    parentRef?: Object;
    controlAnimation?: Function;
    animationYValue?: number;
    index?: number;
    currentChampion?: number;
    description: string;
}

const CategoryFilter = ({
    filter,
    className,
    category,
    handleCategoryChange,
    text,
    enableHoverEffect,
    parentRef,
    controlAnimation,
    animationYValue,
    index,
    currentChampion,
    description,
}: Props) => {
    const [hovering, setHovering] = useState(false);
    const categoryRef = useRef(null);

    const toggleHoverState = (value) => {
        if (window.innerWidth < 1024) {
            return;
        }

        // A way to detect if an item is high enough in the list (relative to animation position) to be able to show the hovering box
        // If an item is too low, the hovering box will be cut off
        // This is probably a bit too complicated for such an easy task, so if you've any suggests please let me know
        // getBoundingClientRect should be a fast function so the performance impact should be minimal
        const parentRect = parentRef.getBoundingClientRect();
        const parentTop = parentRect.y;
        const elRect = categoryRef.current.getBoundingClientRect();
        const relativePos = elRect.y - parentTop;

        // 680 is the predetermined position where the hovering box is fully visible
        if (relativePos < 680) {
            setHovering(value);
            value
                ? controlAnimation.stop()
                : controlAnimation.start({ y: -animationYValue });
        }
    };

    if (category) {
        return (
            <button
                onClick={() => handleCategoryChange(filter)}
                className={` ${
                    category === filter
                        ? `text-purple-600 opacity-100`
                        : ` hover:text-purple-600 focus:text-purple-600 hover:opacity-100 focus:opacity-100`
                } block focus:outline-none transition-all duration-150 ease-easeInOutQuint  w-full p-4 text-xl font-semibold tracking-wider uppercase bg-black opacity-30 ${
                    className ? className : ""
                }`}
            >
                {text}
            </button>
        );
    } else {
        return (
            <div
                className="relative"
                onMouseEnter={() => toggleHoverState(true)}
                onMouseLeave={() => toggleHoverState(false)}
                ref={categoryRef}
            >
                <AnimatePresence>
                    {enableHoverEffect && hovering && (
                        <motion.div
                            transition={{
                                type: "tween",
                                ease: [0.65, 0, 0.35, 1],
                                duration: 0.3,
                            }}
                            initial={{ opacity: 0, y: 32 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 32 }}
                            className="lg:block hidden"
                        >
                            <article
                                className="-left-10 absolute z-50 px-8 py-12 text-black transform -translate-x-full bg-white shadow-2xl opacity-100"
                                style={{ width: "600px" }}
                            >
                                <h2 className="text-xs font-extrabold tracking-wide text-gray-500">
                                    SpaceDelta: Runes
                                </h2>
                                <h1 className="mt-3 mb-5 text-4xl text-purple-300">
                                    {text}
                                </h1>
                                <p
                                    className="text-gray-500"
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                ></p>
                            </article>
                        </motion.div>
                    )}
                </AnimatePresence>

                <div
                    className={`
          text-center focus:outline-none transition-all cursor-pointer duration-300 ease-easeInOutQuint 
         w-full flex items-center justify-center h-16 lg:h-20 px-4 text-sm md:text-xl text-white 
         font-semibold hover:bg-purple-600 hover:opacity-100  tracking-wider uppercase bg-black opacity-30 ${
             className ? className : ""
         }`}
                >
                    <h3>{text}</h3>
                </div>
            </div>
        );
    }
};

export default CategoryFilter;
