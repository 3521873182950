import React from "react";

import { Link } from "gatsby";
// Component imports
import Image from "gatsby-image";
import Text from "./Text";

interface Props {
    category: string;
    title: string;
    desc: string;
    img: any;
    url: string;
}

const LatestPost = ({ category, title, desc, img, url }: Props) => {
    return (
        <div className="md:flex-row lg:grid lg:grid-cols-2 lg:gap-12 xl:gap-20 md:items-center flex flex-col flex-1 w-full">
            <Link to={`/news/${url}`} className="group relative block">
                <div className="xl:w-full overflow-hidden">
                    <img
                        src={img}
                        alt={title}
                        className="md:w-56 lg:h-64 xl:h-80 lg:w-full group-hover:scale-110 ease-easeInOutQuint block object-cover w-full h-56 max-w-full transition-all duration-500 transform"
                    />
                </div>
                {/* Outline */}
                <div className="xl:translate-x-4 xl:-translate-y-4 ease-easeInOutQuint newsHeaderRectangle group-hover:translate-x-0 group-hover:translate-y-0 group-hover:scale-95 absolute inset-0 transition-all duration-500 transform translate-x-2 -translate-y-2 border-2 border-gray-500"></div>
            </Link>
            <Link
                to={`/news/${url}`}
                className="md:mt-0 md:ml-8 hover:text-purple-600 focus:text-purple-600 ease-easeInOutQuint block mt-3 transition-all duration-300"
            >
                <Text
                    variant="p"
                    className="opacity-23 lg:text-sm text-xs font-semibold uppercase"
                >
                    {category}
                </Text>
                <div className="mt-2">
                    <h3 className="lg:text-xl text-lg font-bold tracking-wide">
                        {title}
                    </h3>
                    <Text variant="p" className="text-sm">
                        {desc}
                    </Text>
                </div>
            </Link>
        </div>
    );
};

export default LatestPost;
